cs.ns('app.ui.trait.root.appservices')
app.ui.trait.root.appservices.model = cs.trait({
    protos: {

        create () {
            this.base()
            cs(this).model({
                'global:data:channels':        { value: [], valid: 'object*' },
                'global:data:contents':        { value: [], valid: 'object*' },
                'global:data:selectedChannel': { value: null, valid: 'object' },
                'global:data:currentContent':  { value: null, valid: 'object' },
                'global:data:nextContent':     { value: null, valid: 'object' },
                'data:autoSelectContentHash':  { value: "", valid: 'string' },
                'global:state:paused':         { value: false, valid: 'boolean' },
                'global:state:fullscreenable': { value: false, valid: 'boolean' },
                'global:state:fullscreen':     { value: false, valid: 'boolean' },
                'global:state:embedded':       { value: false, valid: 'boolean' },
                'state:previousPaused':        { value: false, valid: 'boolean' },
                'global:state:anonymous':      { value: false, valid: 'boolean' }
            })
        }

    }
})